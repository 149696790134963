.header{
    display: flex;
    justify-content: space-between;

}
.logo{
    width: 10rem;
    height: 3rem;
}
.Header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color:white ;
}
.Header-menu li{
    cursor: pointer;
}
.Header-menu li:hover{
    color: var(--orange);
    cursor: pointer;
}
@media screen and (max-width:768px){
    .header>:nth-child(2)
    {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .Header-menu{
        flex-direction: column;
        background-color: #3c3f45;
        padding: 2rem;
    }
}